import type { FranchiseBlock } from 'components/plpContext/types/storePLP';
import type { FranchiseBlockFragment } from 'lib/contentful/__generated__/FranchiseBlock';

export const formatFranchiseBlock = (
  entryContent: FranchiseBlockFragment | undefined
) => {
  if (!entryContent) return;

  if (!(entryContent.title || entryContent.description)) {
    return;
  }

  let theme: FranchiseBlock['theme'] = 'light';
  if (entryContent.theme === 'dark') {
    theme = 'dark';
  }

  const formattedData: FranchiseBlock = {
    title: entryContent.title ?? '',
    description: entryContent.description ?? '',
    featuresTitle: entryContent.featuresTitle ?? '',
    theme,
    icons: [],
  };

  if (entryContent.iconsCollection?.items?.length) {
    entryContent.iconsCollection.items.forEach((icon) => {
      if (!icon || !icon.url) {
        return;
      }

      formattedData.icons.push({
        url: icon.url,
        width: icon.width ?? 0,
        height: icon.height ?? 0,
        title: icon.title ?? '',
      });
    });
  }

  return formattedData;
};
