import type { BlogArticleStepProps } from '@we-make-websites/ui-lib';
import type { BlogArticleStepFragment } from 'lib/contentful/__generated__/ModuleBlogArticleStep';

export const formatBlogArticleStep = (
  entryContent: BlogArticleStepFragment
): BlogArticleStepProps => {
  return {
    title: entryContent.title ?? '',
    stepCount: entryContent.stepCount ?? undefined,
    content: entryContent.content ?? '',
    image: entryContent.image?.url
      ? {
          height: entryContent.image?.height ?? 0,
          width: entryContent.image?.width ?? 0,
          title: entryContent.image?.title ?? '',
          url: entryContent.image?.url,
        }
      : undefined,
    ctaTitle: entryContent.ctaTitle ?? '',
    ctaUrl: entryContent.ctaUrl ?? '',
    newTab: entryContent.openLinkInNewTab ?? false,
  };
};
