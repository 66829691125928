import type {
  ColorSelectorProps,
  ColorSelectorProduct,
} from 'components/colorSelector/ColorSelector';
import type { ColorSelectorFragment } from 'lib/contentful/__generated__/ColorSelector';

export const formatColorSelector = (
  entryContent: ColorSelectorFragment
): ColorSelectorProps => {
  const tabs: ColorSelectorProps['tabs'] = [];
  entryContent.tabs?.items.forEach((tab) => {
    if (!tab?.title || !tab?.description || !tab?.products?.items.length)
      return;

    const products: ColorSelectorProduct[] = [];

    tab.products.items.forEach((product) => {
      products.push({
        handle: product?.handle ?? '',
        shadeColor: product?.shadeColor ?? '',
        title: product?.title ?? '',
      });
    });

    tabs.push({
      title: tab.title,
      description: tab.description,
      products,
    });
  });

  return {
    title: entryContent.title ?? '',
    tabs,
    isLoading: false,
  };
};
