import type {
  ArticleCardProps,
  FeaturedArticlesProps,
} from '@we-make-websites/ui-lib';
import type { ArticleFragment } from 'lib/contentful/__generated__/Article';
import type { ModuleFeaturedArticlesFragment } from 'lib/contentful/__generated__/ModuleFeaturedArticles';

/**
 * Formats the entry article.
 * @param article - The article to format.
 */
const formatArticle = (article: ArticleFragment): ArticleCardProps => {
  const url =
    article.categoryRef?.handle && article.handle
      ? `/blog/${article.categoryRef?.handle}/${article.handle}`
      : '';

  const formattedArticle: ArticleCardProps = {
    title: article.title ?? '',
    category: article.categoryRef?.category ?? '',
    url,
    excerpt: article.excerpt ?? '',
  };

  if (article.image) {
    formattedArticle.image = {
      src: article.image.url ?? '',
      height: article.image.height ?? 0,
      width: article.image.width ?? 0,
      alt: article.image.title ?? '',
    };
  }

  return formattedArticle;
};

/**
 * Formats the Featured Articles type.
 * @param entryContent - The Contentful entry content.
 */
export const formatModuleFeaturedArticles = (
  entryContent: ModuleFeaturedArticlesFragment
): FeaturedArticlesProps => {
  const formattedFields: FeaturedArticlesProps = {
    title: entryContent.title ?? '',
    buttonLabel: entryContent.buttonLabel ?? '',
    buttonLink: entryContent.buttonLink ?? '',
    newTab: entryContent.openLinkInNewTab ?? false,
    articles: [],
  };

  if (entryContent.featuredArticle) {
    formattedFields.featuredArticle = formatArticle(
      entryContent.featuredArticle
    );
  }

  entryContent.articlesCollection?.items.forEach((article) => {
    if (
      !article ||
      !article.title ||
      !article.image ||
      !article.categoryRef?.handle
    ) {
      return;
    }

    formattedFields.articles?.push(formatArticle(article));
  });

  return formattedFields;
};
