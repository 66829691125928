import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import IconFillHeart from '../../../icons/misc/fill_heart.svg';
import IconHeart from '../../../icons/misc/heart.svg';
import IconLoading from '../../../icons/misc/loading.svg';
import styles from './WishlistButton.module.scss';

export const WishlistButton = ({
  itemInWishlist,
  className,
  onClick,
  isWishlistItemLoading = false,
  isWishlistLoading = false,
}: {
  /**
   * Whether the item is in the wishlist or not.
   * - Controls the filled/unfilled state of the button
   */
  itemInWishlist?: boolean;
  /**
   * Custom classname for extra style overrides
   */
  className?: string;
  /**
   * Button's on click handler
   */
  onClick?: () => void;
  /**
   * Loading boolean for item
   * - Used to add a loading icon for when the wishlist is loading
   */
  isWishlistItemLoading?: boolean;
  /**
   * Loading boolean for wishlist
   * - Used to disable the wishlist button while the wishlist is updating
   */
  isWishlistLoading?: boolean;
}) => {
  const getIcon = () => {
    if (isWishlistItemLoading) return <IconLoading />;

    return itemInWishlist ? <IconFillHeart /> : <IconHeart />;
  };

  return (
    <button
      className={clsx(styles.wishlistButton, className)}
      type="button"
      onClick={onClick}
      disabled={isWishlistLoading}
    >
      <span className="visually-hidden">
        {itemInWishlist ? (
          <Trans id="wishlist.button.remove">Remove from Wishlist</Trans>
        ) : (
          <Trans id="wishlist.button.add">Add to Wishlist</Trans>
        )}
      </span>

      {getIcon()}
    </button>
  );
};
