import type {
  EducatorCardProps,
  MeetEducatorsProps,
} from '@we-make-websites/ui-lib';
import type { ModuleMeetEducatorsFragment } from 'lib/contentful/__generated__/ModuleMeetEducators';

export const formatModuleMeetEducators = (
  contentEntry: ModuleMeetEducatorsFragment
): MeetEducatorsProps => {
  return {
    title: contentEntry.title ?? '',
    items: contentEntry.educatorsCollection?.items
      .filter(Boolean)
      .map((item) => {
        const fields: EducatorCardProps = {
          name: item?.name ?? '',
          location: item?.location ?? '',
          url: item?.url ?? '',
          newTab: item?.openLinkInNewTab ?? false,
        };

        if (item?.image) {
          fields.image = {
            src: item?.image.url ?? '',
            alt: item?.image.title ?? '',
            height: item?.image.height ?? 0,
            width: item?.image.width ?? 0,
          };
        }

        return fields;
      }),
  };
};
