import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';

import type { FeaturedTutorialProps } from '@we-make-websites/ui-lib';
import type { ModuleFeaturedTutorialFragment } from 'lib/contentful/__generated__/ModuleFeaturedTutorial';

export const formatModuleFeaturedTutorial = (
  entryContent: ModuleFeaturedTutorialFragment
): FeaturedTutorialProps => {
  const props: FeaturedTutorialProps = {
    title: entryContent.title ?? '',
    subtitle: entryContent.subtitle ?? '',
    buttonLabel: entryContent.buttonLabel ?? '',
    buttonLink: entryContent.buttonLink ?? '',
    newTab: entryContent.openLinkInNewTab ?? false,
    content:
      entryContent.tutorialContent &&
      documentToReactComponents(entryContent.tutorialContent.json as Document),
    contentLarge: entryContent.contentLarge ?? false,
  };

  if (entryContent.image) {
    props.image = {
      src: entryContent.image.url ?? '',
      height: entryContent.image.height ?? 0,
      width: entryContent.image.width ?? 0,
      alt: entryContent.image.title ?? '',
    };
  }

  return props;
};
