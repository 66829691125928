import type {
  HeroProps,
  HeroSlideProps,
  VideoHeroProps,
} from '@we-make-websites/ui-lib';
import type { SliderHeroFragment } from 'lib/contentful/__generated__/SliderHero';
import { formatMainHero } from './formatMainHero';
import { formatVideoHero } from './formatVideoHero';

export const formatSliderHero = (
  entryContent: SliderHeroFragment
): HeroProps => {
  const slides = [] as (HeroSlideProps | VideoHeroProps)[];

  if (entryContent.slidesCollection?.items) {
    entryContent.slidesCollection?.items.forEach((item) => {
      if (!item) return;

      if (item.__typename === 'MainHero') {
        slides.push(formatMainHero(item));
      }

      if (item.__typename === 'VideoHero') {
        slides.push(formatVideoHero(item));
      }
    });
  }

  return {
    slides: slides as (HeroSlideProps & VideoHeroProps)[],
  };
};
