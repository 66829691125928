import type { ProductRecommendationsProps } from 'components/productRecommendations/ProductRecommendationsWrapper';
import type { ManualProductRecommendationsFragment } from 'lib/contentful/__generated__/ManualProductRecommendations';

export const formatManualProductRecommendations = (
  entryContent: ManualProductRecommendationsFragment
): ProductRecommendationsProps => {
  const formattedData: ProductRecommendationsProps = {
    title: entryContent.title ?? '',
    collection: {
      handle: entryContent.collection?.handle ?? '',
    },
    description: entryContent.description ?? '',
    filters: entryContent.filters ?? '',
    backgroundColor: Boolean(entryContent.enableBackground),
    ruleContext: entryContent.ruleContext ?? '',
    recommendationsType:
      entryContent.recommendationsType === 'increasingly'
        ? 'increasingly'
        : 'manual',
  };

  if (entryContent.ctaText) {
    formattedData.cta = {
      title: entryContent.ctaText ?? '',
      url: entryContent.ctaUrl ?? '',
      newTab: entryContent.openLinkInNewTab ?? false,
    };
  }

  if (entryContent.descriptionLinkText) {
    formattedData.descriptionLink = {
      title: entryContent.descriptionLinkText ?? '',
      url: entryContent.descriptionLinkUrl ?? '',
      newTab: entryContent.descriptionLinkOpenLinkInNewTab ?? false,
    };
  }

  return formattedData;
};
