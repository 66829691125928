import type { ShareYourLooksProps } from '@we-make-websites/ui-lib';
import type { ShareYourLooksFragment } from 'lib/contentful/__generated__/ShareYourLooks';

export const formatShareYourLooks = (
  entryContent: ShareYourLooksFragment
): ShareYourLooksProps => {
  return {
    title: entryContent.title ?? '',
    description: entryContent.description ?? '',
    image: {
      desktop: {
        width: entryContent.desktopImage?.width ?? 0,
        height: entryContent.desktopImage?.height ?? 0,
        title: entryContent.desktopImage?.title ?? '',
        url: entryContent.desktopImage?.url ?? '',
      },
      mobile: {
        width: entryContent.mobileImage?.width ?? 0,
        height: entryContent.mobileImage?.height ?? 0,
        title: entryContent.mobileImage?.title ?? '',
        url: entryContent.mobileImage?.url ?? '',
      },
    },
    cta: {
      title: entryContent.ctaTitle ?? '',
      url: entryContent.ctaUrl ?? '',
      newTab: entryContent.openLinkInNewTab ?? false,
    },
    isLoading: false,
  };
};
