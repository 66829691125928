import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { HtmlContentProps } from '@we-make-websites/ui-lib/modules/htmlContent/HtmlContent';
import { renderOptions } from 'components/staticPageContext/StaticPageProvider';
import type { HtmlContentFragment } from 'lib/contentful/__generated__/ModuleHtmlContent';
import type { StaticPageContentLinks } from 'lib/contentful/__generated__/types';

export const formatHtmlContent = (
  section: HtmlContentFragment
): HtmlContentProps => {
  const jsonData = (section.htmlContent?.json ?? '') as Document;
  const linksData = section.htmlContent?.links as StaticPageContentLinks;

  return {
    htmlContent: documentToReactComponents(jsonData, renderOptions(linksData)),
    contentTextAlign:
      (section.contentTextAlign?.toLowerCase() as HtmlContentProps['contentTextAlign']) ??
      'left',
  };
};
