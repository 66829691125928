import type {
  ArticleListingProps,
  ArticleCardProps,
} from '@we-make-websites/ui-lib';
import type { ModuleArticleListingFragment } from 'lib/contentful/__generated__/ModuleArticleListing';

export const formatModuleArticleListing = (
  entryContent: ModuleArticleListingFragment
): ArticleListingProps => {
  const articles: ArticleCardProps[] = [];

  entryContent.articlesCollection?.items.forEach((article) => {
    if (!article) {
      return;
    }

    const url =
      article.categoryRef?.handle && article.handle
        ? `/blog/${article.categoryRef?.handle}/${article.handle}`
        : '';

    const formattedArticle: ArticleCardProps = {
      title: article.title ?? '',
      category: article.categoryRef?.category ?? '',
      url,
    };

    if (article.image) {
      formattedArticle.image = {
        src: article.image.url ?? '',
        height: article.image.height ?? 0,
        width: article.image.width ?? 0,
        alt: article.image.title ?? '',
      };
    }

    articles.push(formattedArticle);
  });

  return {
    title: entryContent.title ?? '',
    buttonLabel: entryContent.buttonLabel ?? '',
    buttonLink: entryContent.buttonLink ?? '',
    newTab: entryContent.openLinkInNewTab ?? false,
    articles,
  };
};
