import type { ShelfType } from 'components/plpContext/types/storePLP';
import type { ShelfFragment } from 'lib/contentful/__generated__/Shelf';
import type { InGridContentItem } from 'lib/contentful/__generated__/types';
import type { Maybe } from 'lib/shopify-storefront/__generated__/types';
import { formatInGridContent } from '../inGridContent';
import { formatFranchiseBlock } from './formatFranchiseBlock';

export const formatShelf = (entryContent: ShelfFragment) => {
  const formattedData: ShelfType = {};

  if (entryContent.filters) {
    formattedData.filters = entryContent.filters;
  }

  if (entryContent.franchiseBlock) {
    formattedData.franchiseBlock = formatFranchiseBlock(
      entryContent.franchiseBlock
    );
  }

  if (entryContent.inGridContent?.items) {
    const inGridContent = formatInGridContent(
      entryContent.inGridContent.items as Array<Maybe<InGridContentItem>>
    );

    formattedData.inGridContent = inGridContent;
  }

  if (entryContent.fallbackCollection) {
    formattedData.fallbackCollection = entryContent.fallbackCollection;
  }

  return formattedData;
};
