import type { VideoLessonProps } from '@we-make-websites/ui-lib';
import type { ModuleVideoLessonFragment } from 'lib/contentful/__generated__/ModuleVideoLesson';

export const formatModuleVideoLesson = (
  contentEntry: ModuleVideoLessonFragment
): VideoLessonProps => {
  const fields: VideoLessonProps = {
    title: contentEntry.title ?? '',
    content: contentEntry.description ?? '',
    buttonLabel: contentEntry.buttonLabel ?? '',
    buttonLink: contentEntry.buttonLink ?? '',
    newTab: contentEntry.openLinkInNewTab ?? false,
  };

  if (contentEntry.lessonNumber) {
    fields.lessonNumber = contentEntry.lessonNumber;
  }

  if (contentEntry.video) {
    fields.video = {
      video: {
        url: contentEntry.video.video?.url ?? '',
        contentType: contentEntry.video.video?.contentType ?? '',
      },
      youtubeVideoId: contentEntry.video.youtubeVideoId ?? '',
      vimeoVideoId: contentEntry.video.vimeoVideoId ?? '',
      poster: {
        title: contentEntry.video.videoPoster?.title ?? '',
        url: contentEntry.video.videoPoster?.url ?? '',
        height: contentEntry.video.videoPoster?.height ?? 0,
        width: contentEntry.video.videoPoster?.width ?? 0,
      },
    };
  }

  return fields;
};
