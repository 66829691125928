/* eslint-disable @typescript-eslint/naming-convention */
import type {
  Model3dSource,
  VideoSource,
} from 'lib/shopify-storefront/__generated__/types';

export enum StoreMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  ExternalVideo = 'EXTERNALVIDEO',
  Model3D = 'MODEL3D',
}

type StoreMedia = {
  id: string;
};

export type StoreMediaImage = StoreMedia & {
  type: StoreMediaType.Image;
  image: {
    id: string;
    url: string;
    altText: string;
    width: number;
    height: number;
  };
};

type StoreMediaVideo = StoreMedia & {
  type: StoreMediaType.Video;
  video: {
    id: string;
    alt: string;
    previewImage: string | null;
    sources: VideoSource[];
  };
};

type StoreExternalVideo = StoreMedia & {
  type: StoreMediaType.ExternalVideo;
  externalVideo: {
    id: string;
    embeddedUrl: string;
    host: string;
  };
};

type StoreModel3D = StoreMedia & {
  type: StoreMediaType.Model3D;
  model3D: {
    id: string;
    alt: string;
    previewImage: string | null;
    sources: Model3dSource[];
  };
};

export type StoreMediaNode =
  | StoreMediaImage
  | StoreMediaVideo
  | StoreExternalVideo
  | StoreModel3D;
