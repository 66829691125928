import { WishlistButton } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import { useContext } from 'use-context-selector';
import type { WishlistItemType } from 'backend/utils/wishlist';
import { getCleanProductHandle } from 'utils/helpers';
import { CustomerContext } from '../customerContext/CustomerContext';
import { useWishlist } from '../wishlistContext/WishlistContext';

export const WishlistButtonConnected = ({
  handle,
  className,
  itemType = 'products',
}: {
  handle: string;
  className?: string;
  itemType?: WishlistItemType;
}) => {
  const { customer } = useContext(CustomerContext);
  const wishlist = useWishlist();
  const router = useRouter();

  const sanitizedHandle =
    itemType === 'products' ? getCleanProductHandle(handle) : handle;

  const isItemInWishlist =
    wishlist.list[itemType] &&
    wishlist.list[itemType]?.includes(sanitizedHandle);

  const handleClick = () => {
    /** If customer is logged out, redirect them to the account login */
    if (!customer) {
      router.push('/account/login');
      return;
    }

    const payload = {
      itemType,
      item: sanitizedHandle,
    };

    if (isItemInWishlist) {
      wishlist.remove(payload);
      return;
    }

    wishlist.add(payload);
  };

  const isItemLoading = Boolean(
    wishlist.updatingPayload?.payload?.item === sanitizedHandle
  );

  /**
   * Calculate if the wishlist is loading.
   * - Checks `customer` is set because `isLoading` is true when there is no
   *   customer as the query is in a disabled state.
   */
  const isWishlistLoading =
    !!customer && (wishlist.updating || wishlist.loading);

  return (
    <WishlistButton
      itemInWishlist={isItemInWishlist}
      onClick={handleClick}
      className={className}
      isWishlistItemLoading={isItemLoading}
      isWishlistLoading={isWishlistLoading}
    />
  );
};
